import React from 'react';
// import gql from 'graphql-tag';
// import { graphql } from 'react-apollo';
import Markdown from 'react-markdown';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import LanguageIcon from '@material-ui/icons/Language';

import RelatedInvestigationCard from '../components/RelatedInvestigationCard';

import Layout from '../components/Layout';
import SEO, {SEODescription, SEOImage} from '../components/SEO';

import { graphql } from 'gatsby';


const useStyles = makeStyles((theme) => ({
  articleContainer: {
    padding: theme.spacing(2),
  },
  section: {
    paddingTop: theme.spacing(2),
  },
  amazonLink: {
    // display: 'flex',
    // alignItems: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  }
}));

const Investigator = ({ data }) => {
  const classes = useStyles();
  const investigator = data.gcms.investigator;

  return (
    <Layout>

      <SEO 
        title={investigator.title}
        description={SEODescription(investigator.description.text)}
        image={SEOImage(investigator.image.handle)}
        article={true}
      />

      <Paper className={classes.articleContainer}>
        <article>
          <Typography variant="h3" gutterBottom={true}>
            {investigator.name}
          </Typography>
          <img
            src={`https://media.graphcms.com/resize=w:1200/${investigator.image.handle}`}
            title={investigator.image.title}
            style={{ width: '100%' }}
          />
          <Markdown source={investigator.description.markdown} escapeHtml={false} />

          {investigator.link &&
            <Button
              href={investigator.link}
              target="_blank" rel="noopener"
              className={classes.amazonLink}
              variant="outlined"
              color="primary"
              startIcon={<LanguageIcon />}
            >
              Website
              </Button>
          }

          <Divider variant="middle" />

          {investigator.investigations.length > 0 &&
            <section className={classes.section}>
              <Typography variant="h5" gutterBottom={true}>
                Investigations
                </Typography>
              <Grid container spacing={1}>
                {investigator.investigations.map(investigation => (
                  <RelatedInvestigationCard
                    key={investigation.id}
                    investigation={investigation}
                  />
                ))}
              </Grid>
            </section>
          }

        </article>
      </Paper>
    </Layout>
  );
};

export const query = graphql`
  
  query singleInvestigator($slug: String!) {
    gcms {
      investigator(where: {slug: $slug}) {
        id
        name
        image {
          handle
          title
        }
        description {
          markdown
          text
        }
        link
        investigations {
          id
          title
          slug
          image {
            handle
            title
          }
        }
      }
    }
  }
`;

export default Investigator;






// const Investigator = ({ data: { loading, error, investigator } }) => {
//   const classes = useStyles();

//   if (error) return <h1>Error fetching the investigator!</h1>;
//   if (!loading) {
//     return (
//       <Paper className={classes.articleContainer}>
//         <article>
//           <Typography variant="h3" gutterBottom={true}>
//             {investigator.name}
//           </Typography>
//           <img
//             src={`https://media.graphcms.com/resize=w:1200/${investigator.image.handle}`}
//             title={investigator.image.title}
//             style={{ width: '100%' }}
//           />
//           <Markdown source={investigator.description.markdown} escapeHtml={false} />

//           {investigator.link &&
//             <Button
//               href={investigator.link}
//               target="_blank" rel="noopener"
//               className={classes.amazonLink}
//               variant="outlined"
//               color="primary"
//               startIcon={<LanguageIcon />}
//             >
//               Website
//             </Button>
//           }

//           <Divider variant="middle" />

//           {investigator.investigations.length > 0 &&
//             <section className={classes.section}>
//               <Typography variant="h5" gutterBottom={true}>
//                 Investigations
//               </Typography>
//               <Grid container spacing={1}>
//                 {investigator.investigations.map(investigation => (
//                   <RelatedCard
//                     title={investigation.title}
//                     link={`/investigation/${investigation.slug}`}
//                     imageHandle={investigation.image.handle}
//                     imageTitle={investigation.image.title}
//                   />
//                 ))}
//               </Grid>
//             </section>
//           }

//         </article>
//       </Paper>
//     );
//   }
//   return <h2>Loading investigator...</h2>;
// };

// export const singleInvestigator = gql`
//   query singleInvestigator($slug: String!) {
//     investigator(where: {slug: $slug}) {
//       id
//       name
//       image {
//         handle
//         title
//       }
//       description {
//         markdown
//       }
//       link
//       investigations {
//         title
//         slug
//         image {
//           handle
//           title
//         }
//       }
//     }
//   }
// `;

// export default graphql(singleInvestigator, {
//   options: ({ match }) => ({
//     variables: {
//       slug: match.params.slug,
//     },
//   }),
// })(Investigator);