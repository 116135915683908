import React from 'react';
import RelatedCard from './RelatedCard';

export default function RelatedInvestigationCard(props) {
  const { investigation } = props;

  return (
    <RelatedCard
      title={investigation.title}
      link={`/investigation/${investigation.slug}`}
      imageHandle={investigation.image.handle}
      imageTitle={investigation.image.title}
    />
  );
}